import apis from "../../data/apis.js";
import taxonomies from "../../data/taxonomies.js";
import changelogs from "../../data/changelogs.js";
import bulkdata from "../../data/bulkdata.js";
import pages from "../../data/pages.js";
import getDatasets from "../../data/datasets.js";

import { Maintainers } from "../components/Maintainers";
import Explorer from "../components/AgnitioExplorer.js";

import { renderHtml } from "../helpers/html.js";

import slugify from "./slugify.js";

const getPages = showInternal => {
  return apis
    .map(api =>
      Object.assign({}, api, {
        category: "apis",
        callback: () => {
          setTimeout(async () => {
            // add maintainers through a callback since we can't add them via JSX
            const h1 = document.querySelector("h1");

            const maintainersWrapper = document.createElement("div");
            maintainersWrapper.innerHTML = Maintainers(api.maintainers);
            h1.appendChild(maintainersWrapper);
          }, 200);
        },
      }),
    )
    .concat(
      changelogs.map(changelog =>
        Object.assign({}, changelog, {
          category: "updates",
        }),
      ),
    )
    .concat(
      taxonomies.map(taxonomy =>
        Object.assign({}, taxonomy, {
          category: "taxonomies",
          callback: () => {
            setTimeout(async () => {
              const h1 = document.querySelector("h1");
              const maintainersWrapper = document.createElement("div");
              maintainersWrapper.innerHTML = Maintainers(taxonomy.maintainers);
              h1.appendChild(maintainersWrapper);
            }, 200);
          },
        }),
      ),
    )
    .concat(
      bulkdata.map(bulkdata =>
        Object.assign({}, bulkdata, {
          category: "bulk-data",
          callback: () => {
            setTimeout(async () => {
              // add maintainers through a callback since we can't add them via JSX
              const h1 = document.querySelector("h1");

              const maintainersWrapper = document.createElement("div");
              maintainersWrapper.innerHTML = Maintainers(bulkdata.maintainers);
              h1.appendChild(maintainersWrapper);
            }, 200);
          },
        }),
      ),
    )
    .concat(pages)
    .filter(page => showInternal || !page.internal)
    .filter(page => page.name)
    .map(page =>
      Object.assign({}, page, {
        route: `/${page.category}/${page.slug || slugify(page.name)}`,
        callback: () => {
          if (page.callback) {
            page.callback();
          }
          if (page.internal) {
            document.querySelector("h1").classList.add("internal-only");
          }
        },
      }),
    )
    .sort(pageSort);
};

export default getPages;

export const getDatasetPages = async () => {
  return (await getDatasets()).map(dataset => {
    let content = `# ${dataset.title}
      \n> Ready to use this data? See the ${
        dataset.api
      } [reference documentation](/apis/${slugify(dataset.api)}) to get started.
      \n${dataset.fullDescription}`;

    if (dataset.tags.includes("core lmi")) {
      content += `\n\n## Core LMI Metadata\n\n<div id="agnitio-explorer-root"></div>`;
    }

    return {
      category: "data-sets",
      route: `/data-sets/${slugify(dataset.title)}`,
      name: `${dataset.title} Data`,
      description: dataset.description,
      content,
      callback: () => {
        const root = document.getElementById("agnitio-explorer-root");
        if (root) {
          renderHtml(
            root,
            Explorer({
              id: dataset.id,
              versions: dataset.versions,
            }),
          );
        }
      },
      tags: dataset.tags,
      api: dataset.api,
    };
  });
};

export const getChangelogPages = () => {
  return changelogs.map(changelog => ({
    category: "updates",
    route: `/updates/${slugify(changelog.name)}`,
    name: `${changelog.name}`,
    description: changelog.description,
    content: changelog.description,
    tags: changelog.tags,
    apis: changelog.apis,
  }));
};

// This function is used to get the names of pages associated with an API
// but avoid too much redundancy since from the context it should already
// be clear which API is being referred to. E.g. "Job Postings Data Changelog"
// just becomes "Data Changelog" when displayed under the "Job Postings API".
const getSimplePageName = name => {
  if (name.endsWith("Data Changelog")) {
    return addEmoji("Data Changelog");
  }
  if (
    name.endsWith("Taxonomy Changelog") ||
    name.endsWith("Volume Changelog") ||
    name.endsWith("Translation Changelog")
  ) {
    return addEmoji(name);
  }
  if (name.endsWith("Changelog")) {
    return addEmoji("API Changelog");
  }
  if (name.endsWith("Tutorial")) {
    return addEmoji("Tutorial");
  }

  return name;
};

export const addEmoji = name => {
  if (name.includes("Changelog") && !name.includes("🚀")) {
    return `🚀 ${name}`;
  } else if (name.includes("Tutorial") && !name.includes("🎓")) {
    return `🎓 ${name}`;
  } else {
    return name;
  }
};

export const getRelatedApiPages = (pages, apiName) => {
  const apiPages = pages
    .filter(page => (page.apis || []).includes(apiName))
    .map(page =>
      Object.assign({}, page, {
        name: getSimplePageName(page.name),
      }),
    );

  apiPages.filter(page => page.name).sort(slugSort);
  return apiPages;
};

export const getRelatedTaxonomyPages = (pages, taxonomyName) => {
  const taxonomyPages = pages
    .filter(page => (page.taxonomies || []).includes(taxonomyName))
    .map(page =>
      Object.assign({}, page, {
        name: addEmoji(page.name),
      }),
    );

  taxonomyPages.filter(page => page.name).sort(slugSort);
  return taxonomyPages;
};

const slugSort = (a, b) => slugify(a.name).localeCompare(slugify(b.name));
const pageSort = (a, b) => a.name.localeCompare(b.name);
