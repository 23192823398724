import withProxy from "../helpers/proxy.js";
import bulkdataData from "./bulkdata.json";
import maintainerData from "./maintainers.json";

const bulkdata = bulkdataData.records.map(({ fields }) => ({
  name: fields.Name,
  region: fields.Region,
  description: fields.Description,
  featured: fields.featured,
  internal: !fields.isPublic,
  loginRequired: fields.loginRequired,
  maintainers: maintainerData.records
    .filter(({ id }) => (fields.maintainers || []).includes(id))
    .map(maintainer => maintainer.fields)
    .sort((a, b) => a.name.localeCompare(b.name)),
  scope: fields.scope,
  tags: fields.tags?.sort((a, b) => a.localeCompare(b)),
  url: withProxy(fields.docsUrl),
}));

export default bulkdata;
