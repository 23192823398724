import { useState, useLayoutEffect, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import Hamburger from "react-hamburger-menu";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import {
  faSun,
  faMoon,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NavLink } from "./Link";

import logoWhite from "../images/logo-white-small.png";

import toggleTheme, { setInitialTheme } from "../helpers/theme";
import { useOnClickOutside } from "../helpers/hooks";

const MenuWrapper = styled.button`
  background: none;
  border: none;
  padding: 0;
  display: inline-block;
  cursor: pointer;
  margin-left: 2em;
  position: relative;
  top: 2px;
`;

const ToastMenu = styled.div`
  padding: 2em;
  border-radius: 5px;
  background-color: ${props =>
    props.isLightMode
      ? `var(--primary-base-background-color)`
      : `var(--darker-background-color)`};
  position: fixed;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 1.5em;
  top: 1em;
  right: ${props => (props.open ? "1em" : "-14em")};
  opacity: ${props => (props.open ? "1" : "0")};
  transition: all ease 0.2s;
  z-index: 5;
  color: var(--text-color);
  text-align: left;

  ${props =>
    props.isLightMode
      ? `
  box-shadow: 0px 2px 8px rgba(100, 100, 100, 0.3);
  `
      : ""}

  a {
    margin: 0 !important;
    cursor: pointer;

    ${props =>
      props.isLightMode
        ? `
    &:hover {
      color: var(--primary-sailor-color) !important;
    }
    `
        : ""}
  }

  & > label {
    font-size: 0.9rem;
    font-weight: bolder;
    cursor: pointer;

    input {
      margin-right: 0.5ch;
    }
  }

  div label {
    margin-left: 1em;
  }
`;
const LogoLink = styled.img`
  width: 120px;
`;
const Header = ({ user, isLightcastEmployee, toggleShowInternal }) => {
  const history = useHistory();
  const showInternal = JSON.parse(localStorage.getItem("show-internal"));
  const [internal, setInternal] = useState(showInternal);

  // in case localStorage's "show-internal" flag is true when they _first_ log
  // in, we don't want a conflicting headersearch and "show-internal" status
  useEffect(() => {
    setInternal(showInternal);
  }, [history.location.pathname, showInternal]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [isLightMode, setIsLightMode] = useState(true);
  useLayoutEffect(() => {
    setInitialTheme(() => setIsLightMode(!isLightMode));
  });
  useLayoutEffect(() => {
    setTimeout(() => document.body.classList.remove("initial-load"));
  }, []);

  const prismUri = `/prism-${isLightMode ? "default" : "tomorrow"}`;

  const handleThemeToggle = () => {
    toggleTheme();
    setIsLightMode(!isLightMode);
  };

  const menuNode = useRef();
  useOnClickOutside(menuNode, () => setMenuOpen(false));
  const CloseOnClick = props => (
    <span onClick={() => setMenuOpen(false)} {...props} />
  );

  return (
    <header>
      <Helmet>
        <link rel="stylesheet" href={`${prismUri}.css`} />
      </Helmet>
      <div>
        <NavLink to="/">
          <LogoLink src={logoWhite}></LogoLink>
        </NavLink>
        <input
          onChange={handleThemeToggle}
          className="toggle"
          type="checkbox"
          id="theme"
          checked={!isLightMode}
        ></input>
        <label className="toggle" htmlFor="theme">
          <span>
            <span>
              <FontAwesomeIcon icon={isLightMode ? faSun : faMoon} />
            </span>
          </span>
        </label>
      </div>
      <div className="right">
        <label htmlFor="header-search" id="header-search-label">
          <FontAwesomeIcon icon={faSearch} />
          <input
            id="header-search"
            type="search"
            placeholder="Search documentation..."
            autoComplete="off"
          ></input>
        </label>{" "}
        <NavLink rel="prefetch" to="/data-sets">
          Datasets
        </NavLink>{" "}
        <NavLink rel="prefetch" to="/apis">
          APIs
        </NavLink>{" "}
        <NavLink rel="prefetch" to="/bulk-data">
          Bulk Data
        </NavLink>{" "}
        <NavLink rel="prefetch" to="/taxonomies">
          Taxonomies
        </NavLink>{" "}
        <NavLink rel="prefetch" to="/guides">
          Guides
        </NavLink>{" "}
        <MenuWrapper>
          <Hamburger
            isOpen={menuOpen}
            menuClicked={() => setMenuOpen(!menuOpen)}
            width={21}
            height={15}
            color={
              isLightMode
                ? "var(--primary-base-background-color)"
                : "var(--text-color)"
            }
          />
        </MenuWrapper>
        <ToastMenu open={menuOpen} isLightMode={isLightMode} ref={menuNode}>
          {user && (
            <>
              <UserName>
                <FontAwesomeIcon icon={faUser} />
                <span>
                  {
                    user.profile[
                      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
                    ]
                  }
                </span>
              </UserName>
              {isLightcastEmployee && (
                <label htmlFor="internalfilter" key="internal">
                  <input
                    checked={internal || ""}
                    type="checkbox"
                    name="internalfilter"
                    id="internalfilter"
                    onChange={() => {
                      toggleShowInternal();
                      setInternal(!internal);
                      history.go(0);
                    }}
                  />{" "}
                  Show Internal Content
                </label>
              )}
              {internal && (
                <CloseOnClick>
                  <NavLink rel="prefetch" to="/docs-submission-form">
                    Docs Submission Form
                  </NavLink>
                </CloseOnClick>
              )}
            </>
          )}
          <CloseOnClick>
            <a target="_blank" rel="noreferrer" href="https://kb.lightcast.io/">
              Knowledge Base
            </a>
          </CloseOnClick>
          <CloseOnClick>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://status.lightcast.io/"
            >
              Status Page
            </a>
          </CloseOnClick>
          <CloseOnClick>
            <NavLink rel="prefetch" to="/contact">
              Contact Us
            </NavLink>
          </CloseOnClick>
          {isLightcastEmployee && (
            <CloseOnClick>
              <NavLink rel="prefetch" to="/account">
                Account
              </NavLink>
            </CloseOnClick>
          )}
          <CloseOnClick>
            {user ? (
              <NavLink rel="prefetch" to="/logout">
                Logout
              </NavLink>
            ) : (
              <NavLink rel="prefetch" to="/login">
                Login
              </NavLink>
            )}
          </CloseOnClick>
        </ToastMenu>
      </div>
    </header>
  );
};

export default Header;

const UserName = styled.div`
  font-size: 0.9rem;
  font-weight: bolder;

  svg {
    margin-right: 1ch;
  }
`;
